'use strict';

const validURL = /^https?:\/\/[^\s/$.?#].[^\s]*$/;

const checkURL = url => {
  if (!validURL.test(url)) {
    throw new Error('invalid url');
  }
};

export { checkURL };
