'use strict';

const shareWeb = async event => {
  event.preventDefault();

  const url = window.location.origin + window.location.pathname;

  console.log(url);

  if (navigator.share) {
    try {
      await navigator.share({
        // `title` and `text` properties are buggy, works better without it
        url
      });

      return true;
    } catch (err) {
      console.warn(err);
    }
  } else if (navigator.clipboard) {
    const results = document.getElementById('results');

    try {
      await navigator.clipboard.writeText(url);

      results.innerHTML = `<p>Good news, ${url} copied to clipboard.</p>`;
    } catch (err) {
      console.warn(err);

      results.innerHTML = `<p>Bad news, ${url} not copied to clipboard.</p>`;
    }
  } else {
    alert('share and clipboard not supported');
  }
};

export { shareWeb };
