import '../styles/resets.scss';
import '../styles/base.scss';
import '../styles/header.scss';
import '../styles/form.scss';
import '../styles/footer.scss';
import '../styles/response.scss';

import { shareWeb } from './webShare';

import { handleSubmit } from './submitHandler';

export { handleSubmit, shareWeb };
