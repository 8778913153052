'use strict';

import { checkURL } from './urlChecker';
import { checkResult } from './resultChecker';

const handleSubmit = async event => {
  event.preventDefault();

  const form = event.currentTarget;

  const { elements, action, method } = form;

  const url = elements.url.value;

  const submit = elements.submit;

  submit.disabled = true;

  const results = document.getElementById('results');

  results.innerHTML = `<p>I am trying to read your article right now. Please don't rush me...</p>`;

  // set fetch options
  const init = {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({ url: elements.url.value })
  };

  try {
    checkURL(url);

    const res = await fetch(action, init);

    const json = await res.json();

    checkResult(json);

    const { polarity, subjectivity, polarity_confidence, subjectivity_confidence, formattedText } = json;

    // const formattedText = text.replace(/(?:\r\n|\n|\r)/g, '<br>');

    let response = '';

    switch (polarity) {
      case 'positive':
        response += 'Good news, everyone!';
        break;
      case 'neutral':
        response += 'Neutral news, someone.';
        break;
      case 'negative':
      default:
        response += 'Bad news, nobody.';
        break;
    }

    response += ` I am ${Math.round(polarity_confidence * 100)}% sure that the article is ${polarity} and ${Math.round(
      subjectivity_confidence * 100
    )}% sure that it is ${subjectivity}. Click <a rel="noopener noreferrer" target="_blank" href="${url}">here</a> to read your ${polarity} news.`;

    response += '<br><br>';

    for (let i = 0; i < formattedText.length; ++i) {
      for (let j = 0; j < formattedText[i].length; ++j) {
        response += formattedText[i][j];
      }
      response += '<br><br>';
    }

    results.innerHTML = `<p>${response}</p>`;
  } catch (err) {
    const message = err.message || 'unknown error';
    results.innerHTML = `<p>Bad news, nobody. The super computer has super exploded with these results: ${message}. If you wish to alert somebody, please send a message to my humble servant, <a target="_blank" href="mailto:kevin.godell@gmail.com?&subject=Bad%20news,%20nobody.&body=${message}">kevin.godell@gmail.com</a>.</p>`;
  }

  submit.disabled = false;
};

export { handleSubmit };
